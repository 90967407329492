<template>
  <div class="admin-orders-listing">
    <title-bar title="Edit order" />
    <div class="admin-new-order">
      <StockListing :cart="cart" @stockUpdate="updateCart" />
      <OrderForm
        v-if="orderObj"
        action="update"
        :order-obj="orderObj"
        :cart="cart"
        :enableStatus=true
      />
    </div>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import StockListing from '@/views/Admin/Stock/components/StockListing.vue'
import OrderForm from '@/views/Admin/Orders/components/OrderForm.vue'
import router from '@/router'

export default {
  components: {
    TitleBar,
    StockListing,
    OrderForm,
  },
  data() {
    return {
      orderObj: null,
      cart: [],
    }
  },
  created() {
    this.loadOrder()
  },
  methods: {
    loadOrder() {
      const { id } = router.currentRoute.params
      this.$Orders.getResource({ id }).then(response => {
        const temp = {}
        temp.status = response.data.status
        temp.payment = {
          id: response.data.paymentMethod,
          label: this.$helper.getEnumTranslation(
            'order_payment_method',
            response.data.paymentMethod,
          ),
        }
        temp.postalCode = response.data.shippingAddress.postalCode
        temp.streetAddress = response.data.shippingAddress.streetAddress
        temp.streetNumber = response.data.shippingAddress.streetNumber
        temp.city = response.data.shippingAddress.city
        temp.gate = response.data.shippingAddress.gate
        temp.flatNumber = response.data.shippingAddress.flatNumber
        temp.floorNumber = response.data.shippingAddress.floorNumber
        temp.description = response.data.description
        temp.email = response.data.email ? response.data.email : null
        temp.telephone = response.data.telephone ? response.data.telephone : null
        temp.user = response.data.user
        temp.discount = response.data.discount || 0
        response.data.items.forEach(element => {
          this.cart.push(element)
          this.cart[this.cart.length - 1].stock_update = element.quantity
        })
        temp.fromWarehouse = response.data.fromWarehouse
        temp.shippingDate = response.data.shippingDate
        temp.noticeWP = response.data.noticeWP ? response.data.noticeWP : null

        temp.selectedPhone = null
        if (response.data.telephone) {
            temp.selectedPhone = {
              label: response.data.telephone,
              id: response.data.user ? response.data.user['@id'] : null,
          }
        }

        temp.phoneList = temp.selectedPhone && temp.selectedPhone.id ? [this.selectedPhone] : []

        this.orderObj = temp
      })
    },
    updateCart(newProduct) {
      let updated = false
      this.cart.forEach(product => {
        if (product.id === newProduct.id) {
          updated = true
          if (newProduct.stock_update === 0) {
            const index = this.cart.indexOf(product)
            this.cart.splice(index, 1)
          } else {
            product.stock_update = newProduct.stock_update
          }
        }
      })
      if (!updated && newProduct.stock_update !== 0) {
        this.cart.push(newProduct)
      }
    },
  },
}
</script>
